import React, { useState } from "react"

import { useWpMenu } from "../hooks/wp/useWpMenu"
import { useWpMenuOrder } from "../hooks/wp/useWpMenuOrder"
import { formatMenuQuery } from "../functions/utils/formatMenuQuery"

const Debug = () => {
  const { wpMenu } = useWpMenu()
  const columns = formatMenuQuery(wpMenu, useWpMenuOrder())
  const [menu, setMenu] = useState({
    index: 0,
    content: [{ ...columns.colA }, { ...columns.colB }, { ...columns.colC }],
  })

  const handleClick = i => {
    if (menu.index === i) {
      setMenu(prevState => ({
        content: prevState.content,
        index: menu.index > 0 ? menu.index - 1 : 0,
      }))
    } else {
      setMenu(prevState => ({
        content: prevState.content,
        index: i,
      }))
    }
    console.log(menu)
  }

  return (
    <div className="border-4 border-blue-400 mt-[300px]">
      <h1>Debug</h1>
      <div className="grid grid-cols-3 gap-x-4 py-8 px-20">
        {[0, 1, 2].map((colIndex, index) => {
          var colContent = Object.keys(menu.content[colIndex]).map(function (
            key
          ) {
            return menu.content[colIndex][key]
          })

          return (
            <div
              key={`col-${index}`}
              className={`${
                menu.index === index && "bg-beige "
              }text-center py-1 flex flex-col gap-y-2 items-center`}
            >
              {colContent.map((content, ind) => {
                return (
                  <button
                    key={ind}
                    onClick={() => handleClick(index)}
                    className="bg-gray p-2 rounded-xl hover:shadow-xl hover:px-8 duration-300"
                  >
                    {content.node.title}
                  </button>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Debug
